import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Button from '@/components/Button'
import Typography from '@/components/Typography'
import { PrimaryTitleContainer } from '@/components/WrapperContentItems/components/PrimaryTitle/PrimaryTitle.styles'
import SectionWrapper from '@/components/SectionWrapper'

export const Wrapper = styled(SectionWrapper)`
  width: 100%;
  margin: auto;
  background: ${({ theme }) => theme.sectionTheme?.background};
`

export const SmallTitle = styled(Typography).attrs({
  variant: TextStyles['Overline'],
})`
  color: ${({ theme }) => theme.colors.black02};
  margin-bottom: 32px;
  text-align: center;
`

export const Title = styled(PrimaryTitleContainer)`
  margin-bottom: 30px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 60px;
  }
`

export const LogosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  opacity: 0.9;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    gap: 32px;

    // Set max height to prevent the logos from taking up more than 2 rows in desktop.
    max-height: 136px;
    overflow-y: hidden;
  }
`

export const LogoImage = styled.img``

export const Logo = styled.div`
  filter: grayscale(1);

  ${LogoImage}, > .dotlottie-container {
    width: 140px;
    height: 48px;
  }
`

export const SecondaryButton = styled(Button).attrs({ variant: 'secondary' })`
  display: flex;
  margin: auto;
  margin-top: 50px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 60px;
  }
`

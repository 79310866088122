import styled from 'styled-components'

export const HeroImage = styled.img``

export const ProductSuiteWrapper = styled.div`
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: unset;
  }

  ${HeroImage}, .dotlottie-container {
    width: 100%;
    display: block;
  }
`

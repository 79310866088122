import styled from 'styled-components'
import { TextStyles } from '@/constants'
import {
  Button, DynamicLink, Typography 
} from '@/components'
import { styledText } from '@/components/Typography/Typography.styles'
import { TopicName } from '../../Article/Article.styles'

export const FeaturedArticleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 50px 0 18px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 0;
  }
`

export const HeroImageLinkContainer = styled(DynamicLink)`
  width: 100%;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: calc(50% - 16px);
  }
`

export const ThumbnailContentContainer = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: calc(50% - 17px);
  }
`

export const FeaturedButtonMobile = styled(Button)`
  display: flex;
  margin: 0 auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`

export const FeaturedButtonDesktop = styled(Button)`
  display: none;
  margin: 0 auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: inline-flex;
  }
`

export const HeroImage = styled.img`
  display: block;
  width: 100%;
  height: 191px;
  margin-bottom: 20px;
  border-radius: 4px;
  object-fit: cover;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 0;
    height: 334.8px;
    border-radius: 10px;
  }
`

export const ThumbnailItemList = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 4px;
  }
`

export const ThumbnailTopicName = styled(TopicName)`
  margin: 0 10px 8px 0;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 0 12px 12px 0;
  }
`

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  margin-top: 0px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.darkBlue};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 2']]}
    margin-bottom: 10px;
  }
`

export const SubCopy = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  color: ${({ theme }) => theme.colors.darkBlue};
`

export const CreatedDate = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  margin-top: 8px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.lightGray};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 10px 0;
  }
`

import { useEffect, useState } from 'react'
import App, { AppProps, AppContext } from 'next/app'
import Script from 'next/script'
import { ThemeProvider } from 'styled-components'
import getConfig from 'next/config'
import absoluteUrl from 'next-absolute-url'
import { useRouter } from 'next/router'

// Component
import Layout from '@/components/Layout'
import GlobalStyles from '@/components/GlobalStyles'
import { GlobalStateProvider } from '@/hooks/useGlobalState.hook'
import { LanguageProvider } from '@/hooks/useLanguageData'
import { URLParsingProvider } from '@/hooks/useParseUrl'

// Util
import {
  parseCookie,
  checkIsPreviewModeRequest,
  isEU,
  isCali,
  fetchGeoAPI,
  setHasVisited,
  showCookieBannerPopup,
  checkIsErrorPage,
  getPageSlug,
  checkIsArticlePageApp,
  checkIsCategoryPageApp,
  checkIsEventPageApp,
  excludeUndefinedKey,
  checkIsResourcesPageApp,
  getMatchingPopUps,
  checkIsIndividualGlossaryPage,
} from '@/utils'

// Fetcher
import {
  getFooter,
  getHeaderItem,
  getFullPageSmallContent,
  getFullPageSlugs,
  getBlogHeaderItem,
  getArticleSmallContent,
  getStickySubscribeBanner,
  getArticleSlugs,
  getCategorySmallContent,
  getCategorySlugs,
  getPopups,
  getEventSmallContent,
  getGlossarySmallContent,
} from '@/fetchers'

// Type
import { DOMAIN_LOCALES } from '@/interfaces/locales'
import { IGeo } from '@/interfaces/geo'
import { HeaderItemInterface } from '@/interfaces/headerItem'
import { FooterInfor } from '@/interfaces/footer'
import { IFormProps } from '@/interfaces/form'
import { IPopUpProps } from '@/interfaces/popUp'

// Constant
import {
  theme,
  UTMKeys,
  REDIS_APP_KEY,
  REDIS_POPUP_KEY,
  REDIS_HOMEPAGE_KEY,
  EUCountryCodes,
} from '@/constants'
import { DEVELOPMENT_HOST, PLATO_LOGIN_TOKEN } from '@/constants/app.constants'

const DEFAULT_LIVE_CHAT_MARGIN_BOTTOM = 0
const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()

const MyApp = ({ Component, pageProps }: AppProps): React.ReactNode => {
  const {
    navData,
    blogNavData,
    shouldPassKeyToComponent,
    footerData,
    isLocalhost,
    currentDomain,
    languageData,
    hideHeaderFooter,
    hideCookieBanner,
    urlParsingData,
    stickySubscribeBanner,
    isRedisApp,
    isRedisPopUp,
    popUp,
    errorCode,
  } = pageProps

  // eslint-disable-next-line no-console
  if (isRedisApp) console.log('v.2.2')
  // eslint-disable-next-line no-console
  if (isRedisPopUp) console.log('v.2.3')

  const [hasCookieBanner, setCookieBanner] = useState(false)
  const [liveChatMarginBottom, setLiveChatMarginBottom] = useState(
    DEFAULT_LIVE_CHAT_MARGIN_BOTTOM
  )
  const [geoInfo, setGeoInfo] = useState<IGeo | null>({})

  useEffect(() => {
    fetchGeoAPI().then((response) => setGeoInfo(response))
  }, [])

  useEffect(() => {
    if (geoInfo) {
      const checkForCookieBanner =
        !hideCookieBanner &&
        showCookieBannerPopup() &&
        geoInfo &&
        !geoInfo.error &&
        !isEU(geoInfo) &&
        !isCali(geoInfo)
      setCookieBanner(checkForCookieBanner)
    }
  }, [geoInfo])

  // Set has_visited in local storage
  setHasVisited()

  const ScriptComponent = () => {
    return (
      <>
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              // Define dataLayer and the gtag function.
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}

              gtag('consent', 'default', {
                'ad_personalization': 'granted',
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'analytics_storage': 'granted',
              });

              gtag('consent', 'default', {
                'ad_personalization': 'denied',
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'analytics_storage': 'denied',
                'region': [
                  'US-CA',
                  ${EUCountryCodes.map((countryCode) => `'${countryCode}'`).join(',')}
                ],
              });
            `,
          }}
        />
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;

            j.addEventListener('load', function() {
              var _ge = new CustomEvent('gtm_loaded', { bubbles: true });
              d.dispatchEvent(_ge);
            });

            f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${publicRuntimeConfig.GTM_ID}');
          `,
          }}
        />
        {/* GA3 is config in codde; GA4 is config on GTM */}
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.GA3_ID}`}
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${publicRuntimeConfig.GA3_ID}');
          `,
          }}
        />
      </>
    )
  }

  useEffect(() => {
    const cookieBanner: any = document.getElementById('pp-cookie-banner')

    if (!cookieBanner) {
      const CCPA = document.getElementById('pp_ccpa_container')

      const observer = new MutationObserver((mutationsList) => {
        // This callback function will be called whenever there are changes in the DOM
        mutationsList.forEach((mutation) => {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            const firstAddedNode = mutation.addedNodes[0] as HTMLElement
            const offsetHeight = firstAddedNode.offsetHeight
            setLiveChatMarginBottom(offsetHeight ?? 0)
          }
        })
      })

      if (CCPA) observer.observe(CCPA, { childList: true })
      return
    }

    setLiveChatMarginBottom(cookieBanner?.offsetHeight ?? 0)

    const cookieBannerCloseBtn = document.getElementById(
      'pp-cookie-banner-close-btn'
    )
    cookieBannerCloseBtn?.addEventListener('click', () => {
      setLiveChatMarginBottom(DEFAULT_LIVE_CHAT_MARGIN_BOTTOM)
    })
  }, [hasCookieBanner])

  const router = useRouter()

  const pageUrl = urlParsingData.origin + router.asPath

  const pageParams = router.query

  const utmData: Record<UTMKeys, unknown> | null = excludeUndefinedKey({
    [UTMKeys.utmCampaign]: pageParams[UTMKeys.utmCampaign],
    [UTMKeys.utmContent]: pageParams[UTMKeys.utmContent],
    [UTMKeys.utmMedium]: pageParams[UTMKeys.utmMedium],
    [UTMKeys.utmSource]: pageParams[UTMKeys.utmSource],
    [UTMKeys.utmTerm]: pageParams[UTMKeys.utmTerm],
  })

  return (
    <GlobalStateProvider
      pageUrl={pageUrl}
      geoInfo={geoInfo}
      pageParams={pageParams}
      utmData={utmData}
      isHomepage={router.asPath.split('?')[0] === '/'}
    >
      <LanguageProvider languageData={languageData}>
        <URLParsingProvider urlParsingData={urlParsingData}>
          <ThemeProvider theme={theme}>
            <GlobalStyles
              liveChatMarginBottom={
                !errorCode
                  ? liveChatMarginBottom
                  : DEFAULT_LIVE_CHAT_MARGIN_BOTTOM
              }
            />
            <Layout
              navData={navData}
              blogNavData={blogNavData}
              currentDomain={currentDomain}
              footerData={footerData}
              hideHeaderFooter={hideHeaderFooter || !!errorCode}
              hasCookieBanner={hasCookieBanner && !errorCode}
              stickySubscribeBanner={stickySubscribeBanner}
              popUp={popUp}
            >
              {!isLocalhost && <ScriptComponent />}
              {!!errorCode && shouldPassKeyToComponent ? (
                <Component {...pageProps} key={router.asPath} />
              ) : (
                <Component {...pageProps} />
              )}
            </Layout>
          </ThemeProvider>
        </URLParsingProvider>
      </LanguageProvider>
    </GlobalStateProvider>
  )
}

interface IAppServerSideProps {
  header: HeaderItemInterface[] | null
  blogHeader: HeaderItemInterface[] | null
  footer: FooterInfor[] | null
  form: IFormProps | null
}

MyApp.getInitialProps = async (ctx: AppContext) => {
  let getServerPropsCached: (pageURL: string) => Promise<string> = (() =>
    null) as any
  let cacheServerProps: (pageURL: string, data: Record<string, any>) => void =
    () => null

  if (!serverRuntimeConfig.useDrug) {
    const redisUtils = await import('@/utils/redis.utils')

    getServerPropsCached = redisUtils.getServerPropsCached
    cacheServerProps = redisUtils.cacheServerProps
  }

  const { host, origin } = absoluteUrl(ctx.ctx.req)

  const { pathname } = ctx.ctx

  try {
    decodeURIComponent(ctx.ctx.asPath || '')
  } catch (error) {
    return {
      pageProps: {
        errorCode: 404,
        urlParsingData: { host, origin },
      },
    }
  }

  const fullPageSlug = ctx.ctx.asPath?.split('?')[0] || '/'
  const cachedPageSlug =
    fullPageSlug === '/' ? REDIS_HOMEPAGE_KEY : fullPageSlug
  let pageSlug = getPageSlug(
    ctx.ctx?.query.slug,
    ctx.ctx.asPath?.split('?')[0],
    ctx.ctx?.query
  )

  const isArticlePage = checkIsArticlePageApp(pathname)
  const isCategoryPage = checkIsCategoryPageApp(pathname)
  const isEventPage = checkIsEventPageApp(pageSlug)
  const isGlossaryPage = checkIsIndividualGlossaryPage(pathname)

  const articleLang = !Array.isArray(ctx.ctx?.query.lang)
    ? ctx.ctx?.query.lang || ''
    : ''
  if (ctx.ctx?.query.slug && ctx.ctx?.query.lang) {
    pageSlug = getPageSlug(ctx.ctx?.query.slug)
  }

  const currentDomain =
    host === DEVELOPMENT_HOST ? publicRuntimeConfig.webDomain : host
  const defaultLocale = DOMAIN_LOCALES[publicRuntimeConfig.webDomain]
  const currentLocale = DOMAIN_LOCALES[host] || defaultLocale
  const isPreviewMode = checkIsPreviewModeRequest(ctx.router?.query)
  const isProd = serverRuntimeConfig.contentfulEnvironment === 'master' &&
  serverRuntimeConfig.webDomain === 'www.parcelperform.com'
  const { platoLoginToken } = parseCookie(ctx.ctx.req?.headers.cookie || '')
  let isRedisApp = false
  let isRedisPopUp = false

  let pageContent = null
  const serverLanguageSlugsPropsCached = JSON.parse(
    (await getServerPropsCached(cachedPageSlug)) || 'null'
  )
  if (serverLanguageSlugsPropsCached) {
    // TODO: document serverLanguageSlugsPropsCached
    // Note: require "data" field in the page cached for "pageData" works fine
    const pageData = serverLanguageSlugsPropsCached.data
    pageContent = {
      sys: {
        ...pageData.sys,
      },
      hideHeaderFooter: pageData.hideHeaderFooter,
      languageData: serverLanguageSlugsPropsCached.languageData,
    }
  } else {
    let action = null

    if (isArticlePage) {
      action = await getArticleSmallContent(
        isPreviewMode,
        pageSlug,
        DOMAIN_LOCALES[currentDomain]?.code,
        articleLang
      )
    } else if (isCategoryPage) {
      action = await getCategorySmallContent(
        isPreviewMode,
        pageSlug,
        DOMAIN_LOCALES[currentDomain]?.code
      )
    } else if (isEventPage) {
      action = await getEventSmallContent(
        isPreviewMode,
        pageSlug,
        DOMAIN_LOCALES[currentDomain]?.code
      )
    } else if (isGlossaryPage) {
      action = await getGlossarySmallContent({
        isPreviewMode,
        pageSlug: ctx.ctx?.query.slug as string,
        locale: DOMAIN_LOCALES[currentDomain]?.code,
      })
    } else {
      action = await getFullPageSmallContent(
        isPreviewMode,
        pageSlug,
        DOMAIN_LOCALES[currentDomain]?.code
      )
    }

    pageContent = action
    const languageRes = isArticlePage
      ? await getArticleSlugs(
        isPreviewMode,
        pageSlug,
        currentDomain,
        pageContent?.sys?.id,
        articleLang
      )
      : isCategoryPage
        ? await getCategorySlugs(
          isPreviewMode,
          pageSlug,
          currentDomain,
          pageContent?.sys?.id
        )
        : await getFullPageSlugs(
          isPreviewMode,
          pageSlug,
          currentDomain,
          pageContent?.sys?.id
        )
    pageContent = pageContent && {
      sys: {
        ...pageContent.sys,
      },
      hideHeaderFooter: pageContent.hideHeaderFooter,
      languageData: languageRes,
    }
  }

  // Get all popups and get matched popup
  let initialPopUps = null
  const serverPopUpsPropsCached: { popUps: IPopUpProps[] } = JSON.parse(
    (await getServerPropsCached(REDIS_POPUP_KEY)) || 'null'
  )
  if (serverPopUpsPropsCached) {
    initialPopUps = serverPopUpsPropsCached.popUps
    isRedisPopUp = true
  } else {
    initialPopUps = await getPopups(isPreviewMode, currentLocale.code)
    if (!isPreviewMode) {
      cacheServerProps(REDIS_POPUP_KEY, {
        popUps: initialPopUps,
      })
    }
  }

  const matchedPopUps = getMatchingPopUps(
    ctx.ctx.asPath?.split('?')[0],
    initialPopUps
  )
  const popUp = matchedPopUps?.length ? matchedPopUps[0] : null

  const appServerProps: IAppServerSideProps = {
    header: null,
    blogHeader: null,
    footer: null,
    form: null,
  }

  // Get header, footer, popup regardless of page url
  const serverPropsCached: IAppServerSideProps = JSON.parse(
    (await getServerPropsCached(REDIS_APP_KEY)) || 'null'
  )
  if (serverPropsCached) {
    appServerProps.header = serverPropsCached.header
    appServerProps.blogHeader = serverPropsCached.blogHeader
    appServerProps.footer = serverPropsCached.footer
    appServerProps.form = serverPropsCached.form
    isRedisApp = true
  } else {
    const inAppActions = [
      getHeaderItem(isPreviewMode, currentLocale.code),
      getBlogHeaderItem(isPreviewMode, currentLocale.code),
      getFooter(isPreviewMode, currentLocale.code),
      getStickySubscribeBanner(isPreviewMode, currentLocale.code),
    ]
    const inAppResponse = await Promise.all(inAppActions)
    appServerProps.header = inAppResponse[0]
    appServerProps.blogHeader = inAppResponse[1]
    appServerProps.footer = inAppResponse[2]
    appServerProps.form = inAppResponse[3]
    if (!isPreviewMode) {
      cacheServerProps(REDIS_APP_KEY, appServerProps)
    }
  }
  const {
    header, blogHeader, footer, form 
  } = appServerProps

  const appProps = await App.getInitialProps(ctx)

  const isErrorPage = checkIsErrorPage(pathname) || !pageContent
  const stickySubscribeBanner =
    !isErrorPage && (isArticlePage || isCategoryPage) ? form : undefined

  const hasBlogNavData =
    pageContent &&
    (isArticlePage || isCategoryPage) &&
    !articleLang &&
    blogHeader &&
    blogHeader?.length > 0
      ? blogHeader[0]
      : pageContent && (isArticlePage || isCategoryPage)
        ? {}
        : null

  const isResourcePage = checkIsResourcesPageApp(ctx.ctx.query?.slug?.[0] || '')

  return {
    pageProps: {
      ...appProps.pageProps,
      navData: header || null,
      blogNavData: hasBlogNavData,
      shouldPassKeyToComponent: Boolean(hasBlogNavData || isResourcePage),
      footerData: footer || null,
      isLoggedIn: platoLoginToken === PLATO_LOGIN_TOKEN,
      isLocalhost: host === DEVELOPMENT_HOST,
      currentDomain,
      isProd,
      languageData: pageContent?.languageData || null,
      hideHeaderFooter: isErrorPage || (pageContent?.hideHeaderFooter ?? false), //to omit the null case for type definition
      hideCookieBanner: isErrorPage,
      urlParsingData: { origin, host },
      stickySubscribeBanner: !articleLang ? stickySubscribeBanner : null,
      isRedisPopUp,
      isRedisApp,
      popUp,
    },
  }
}

export default MyApp

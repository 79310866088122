import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Component
import {
  Wrapper,
  Title,
  SliderItem,
  SliderWrapper,
  StyledImage,
  SecondaryButton,
} from './Animated.styles'
import {
  Container,
  LocalThemeProvider 
} from '@/components'

// Type
import { CarrierProps } from '@/interfaces/carrier'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'

// Util
import { getSectionTheme } from '@/utils'

// Hook
import { useWindowSize, useHighlightString } from '@/hooks'
import { SectionSeparator } from '@/components/WrapperContentItems/components'

const Animated: React.FC<CarrierProps> = (props) => {
  const {
    title,
    firstCarrierList,
    secondCarrierList,
    buttonText,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
    withSeparator,
  } = props
  const { width } = useWindowSize()
  const ITEM_WIDTH_ON_DESKTOP = 152
  const ITEM_WIDTH_ON_MOBILE = 82
  const SETTINGS = {
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    swipe: false,
    pauseOnHover: false,
  }
  const firstCarriers = firstCarrierList?.filter((item: any) => item)
  const secondCarriers = secondCarrierList?.filter((item: any) => item)

  const calculateShowItem = (item: any, maxItemShow: number) => {
    return item.length > maxItemShow
      ? maxItemShow
      : item.length > 1
        ? item.length - 1
        : item.length
  }

  const calculateWidth = (item: any) => {
    if (width > 599)
      return (
        calculateShowItem(item, width / ITEM_WIDTH_ON_DESKTOP) *
        ITEM_WIDTH_ON_DESKTOP
      )
    return (
      calculateShowItem(item, width / ITEM_WIDTH_ON_MOBILE) *
      ITEM_WIDTH_ON_MOBILE
    )
  }

  return (
    <LocalThemeProvider
      theme={{ sectionTheme: getSectionTheme(SectionThemeKey.Light) }}
    >
      <Wrapper>
        <Container>
          {title && <Title>{useHighlightString(title)}</Title>}
          <SliderWrapper>
            {!!firstCarriers?.length && (
              <Slider
                {...SETTINGS}
                slidesToShow={calculateShowItem(
                  firstCarriers,
                  width / ITEM_WIDTH_ON_DESKTOP
                )}
                rtl={true}
                style={{ width: calculateWidth(firstCarriers) }}
                responsive={[
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: calculateShowItem(
                        firstCarriers,
                        width / ITEM_WIDTH_ON_MOBILE
                      ),
                    },
                  },
                ]}
              >
                {firstCarriers.map(({
                  sys, url, description 
                }) => (
                  <SliderItem key={sys?.id}>
                    <StyledImage
                      src={url}
                      alt={description || 'Carrier logo'}
                      fill
                      sizes='100vw'
                      placeholder='blur'
                      blurDataURL='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNcVQ8AAdkBKwhkHIIAAAAASUVORK5CYII='
                    />
                  </SliderItem>
                ))}
              </Slider>
            )}
            {!!secondCarriers?.length && (
              <Slider
                {...SETTINGS}
                slidesToShow={calculateShowItem(
                  secondCarriers,
                  width / ITEM_WIDTH_ON_DESKTOP
                )}
                style={{ width: calculateWidth(secondCarriers) }}
                responsive={[
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: calculateShowItem(
                        secondCarriers,
                        width / ITEM_WIDTH_ON_MOBILE
                      ),
                    },
                  },
                ]}
              >
                {secondCarriers.map(({
                  sys, url, description 
                }) => (
                  <SliderItem key={sys?.id}>
                    <StyledImage
                      src={url}
                      alt={description || 'Carrier Logo'}
                      fill
                      sizes='100vw'
                    />
                  </SliderItem>
                ))}
              </Slider>
            )}
          </SliderWrapper>
          {buttonLink && buttonText && (
            <SecondaryButton
              href={buttonLink}
              eventLabel={buttonEventLabel}
              openInNewTab={openInNewTab}
            >
              {buttonText}
            </SecondaryButton>
          )}
        </Container>
      </Wrapper>
      {withSeparator && <SectionSeparator />}
    </LocalThemeProvider>
  )
}

export default Animated

import styled from 'styled-components'
import Image from 'next/image'
import { TextStyles } from '@/constants'
import Button from '@/components/Button'
import SectionWrapper from '@/components/SectionWrapper'
import { styledText } from '@/components/Typography/Typography.styles'
import { PrimaryTitleContainer } from '@/components/WrapperContentItems/components/PrimaryTitle/PrimaryTitle.styles'

export const Wrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.sectionTheme?.background};
`

export const Title = styled(PrimaryTitleContainer)`
  margin-bottom: 48px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
    margin-bottom: 80px;
  }
`

export const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .slick-slider:not(:last-child) {
    margin-bottom: 14px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin-bottom: 20px;
    }
  }

  .slick-next {
    right: -25px;
  }
`

export const SecondaryButton = styled(Button).attrs({ variant: 'secondary' })`
  display: flex;
  margin: auto;
  margin-top: 20px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 60px;
  }
`

export const SliderItem = styled.div`
  position: relative;
  height: 75px !important;
  width: 75px !important;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 128px !important;
    width: 128px !important;
  }
`

export const StyledImage = styled(Image)`
  border-radius: 4.67px;
  background-color: white;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 0px 10px -5px rgba(0, 0, 0, 0.04);

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    border-radius: 8px;
  }
`

import { CustomEventName } from '@/constants'
import { resetGTMDataLayer } from '..'

export const GTMPageLoad = (): void => {
  resetGTMDataLayer()
  const pageEvent = {
    event: CustomEventName.PageLoad,
  }

  window?.dataLayer?.push(pageEvent)
}

// TODO-Typo: NOT EDIT YET, belongs to Nav font system (not mapped yet)
import styled from 'styled-components'

export const Header = styled.div`
  background: ${({ theme }) => theme.colors.darkBlue};
  height: 70px;
  display: flex; 
  align-items: center;
  position: relative;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 91px;
  }
`

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: 5px;
    padding-bottom: 10px;
  }
`

export const Logo = styled.a`
  img {
    width: 209px;
    height: 67px;
    object-fit: cover;
    display: block;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      width: 234px;
      height: 75px;
    }
  }
`

export const LoginButton = styled.a`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[24]};
  margin: 20px 0;
  display: block;
  padding: 4px 0;

  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSizes[16]};
    line-height: ${({ theme }) => theme.lineHeight[22]};
    display: initial;
    margin: unset;
    padding: unset;
  }
`

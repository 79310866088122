import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Button from '@/components/Button'
import Typography from '@/components/Typography'
import ArrowForward from '../../../../public/images/icons/arrow-forward.svg'

export const SupportSection = styled.div`
  ${({ theme }) =>  theme.mediaBreakpointUp.md} {
    width: 260px;
    padding: 8px;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  height: 135px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 2px;
  overflow: hidden;
`

export const Content = styled(Typography).attrs({
  variant: TextStyles['Small Paragraph Text'],
})`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.black02};
`

// NEW-BUTTON: Text - Product Experience - Header
export const CustomTextButton = styled(Button).attrs({
  variant: 'link',
  icon: ArrowForward,
})`
  position: relative;
  padding: 4px 0;

  // Custom for only Text button
  padding: 4px 0 3px 0;
  border-radius: 0 !important;
  border-bottom: 2px solid transparent;

  :not([disabled]):hover {
    color: ${({ theme }) => theme.colors.orangeHover} !important;
    border-color: ${({ theme }) => theme.colors.orangeHover} !important;

    & > span > svg,
    & > svg {
      &, & path {
        fill: ${({ theme }) => theme.colors.orangeHover} !important;
      }
    }
  }
`

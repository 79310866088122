import {
  RefObject,
  useRef,
  createRef
} from 'react'
import PrimaryTitle from '../../components/PrimaryTitle'
import SecondaryTitle from '../../components/SecondaryTitle'
import WrapperContentItemsContainer from '../../components/WrapperContentItemsContainer'
import { Wrapper, SecondaryButton } from './MultipleIconAndCopy.styles'

import { getSectionTheme } from '@/utils'
import useTitleHeight from '@/hooks/useTitleHeight'
import { ContentItem, RichText } from '@/components'
import LocalThemeProvider from '@/components/LocalThemeProvider'
import PageContentContainer from '@/components/Container'
import { WrapperContentInterface } from '@/interfaces/wrapperContent'
import { SubTitleWrapper } from '../../WrapperContentItems.styles'

const MultipleIconAndCopy: React.FC<WrapperContentInterface> = ({
  primaryTitle,
  secondaryTitle,
  subTitle,
  theme,
  itemList,
  withSeparator,
  buttonLink,
  buttonText,
  buttonEventLabel,
  openInNewTab,
}) => {
  const titleRefs = useRef<RefObject<HTMLElement>[]>(Array.from({ length: itemList?.length }, () => createRef()))
  const height = useTitleHeight(titleRefs.current)

  return (
    <LocalThemeProvider theme={{ sectionTheme: getSectionTheme(theme) }}>
      <WrapperContentItemsContainer withSeparator={withSeparator}>
        <PageContentContainer>
          {secondaryTitle && <SecondaryTitle>{secondaryTitle}</SecondaryTitle>}
          <PrimaryTitle>{primaryTitle}</PrimaryTitle>
          {subTitle && (
            <SubTitleWrapper>
              <RichText richText={subTitle} />
            </SubTitleWrapper>
          )}
          <Wrapper>
            {itemList
              ?.filter((item: any) => item)
              ?.map((item: any, index: number) => {
                // TODO_2: Change component based on __typename, for now default is ContentItem
                // Will need to add a wrapper for forwarding ref if ContentItem is dynamic import
                return <ContentItem
                  data={item}
                  key={index}
                  ref={titleRefs.current[index]}
                  height={height} />
              })}
          </Wrapper>
          {buttonLink && buttonText && (
            <SecondaryButton
              href={buttonLink}
              eventLabel={buttonEventLabel}
              openInNewTab={openInNewTab}
            >
              {buttonText}
            </SecondaryButton>
          )}
        </PageContentContainer>
      </WrapperContentItemsContainer>
    </LocalThemeProvider>
  )
}

export default MultipleIconAndCopy

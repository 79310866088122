import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  forwardRef, MouseEventHandler, useMemo 
} from 'react'
import { StyledComponent } from 'styled-components'
import { getSectionTheme, GTMButtonClick } from '../../utils'
import { useGlobalState } from '@/hooks'
import {
  IconButton,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  Hyperlink,
} from './VariantStyles'
import { GA4ButtonClick } from '@/utils/GTM/GTMButtonClick'
import { ButtonProps } from '@/interfaces/button.interfaces'

const getStylingOfVariant = (
  variant: 'primary' | 'secondary' | 'link' | 'icon' | 'hyperlink' | undefined
): {
  ButtonComponent: StyledComponent<any, any, any, any>
  ButtonLink: StyledComponent<any, any, any, any>
} => {
  switch (variant) {
    case 'link':
      return {
        ButtonComponent: LinkButton.Button,
        ButtonLink: LinkButton.ButtonLink,
      }
    case 'secondary':
      return {
        ButtonComponent: SecondaryButton.Button,
        ButtonLink: SecondaryButton.ButtonLink,
      }
    case 'icon':
      return {
        ButtonComponent: IconButton.Button,
        ButtonLink: IconButton.ButtonLink,
      }
    case 'hyperlink':
      return {
        ButtonComponent: Hyperlink.Button,
        ButtonLink: Hyperlink.ButtonLink,
      }
    default:
      return {
        ButtonComponent: PrimaryButton.Button,
        ButtonLink: PrimaryButton.ButtonLink,
      }
  }
}

const getPathnameFromURL = (url: string): string => {
  try {
    const parsedURL = new URL(url)

    return parsedURL.pathname
  } catch {
    return ''
  }
}

const Button: React.ForwardRefRenderFunction<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps
> = (props, ref) => {
  const { ButtonComponent, ButtonLink } = getStylingOfVariant(props.variant)
  // This icon is to obey the styling of origin design
  const Icon = props.icon

  const customButtonOnClick = () => {
    if (props.onClick) props.onClick()
    if (props.eventLabel) GTMButtonClick(props.eventLabel)
    // * new custom event
    if (props.eventLabel === 'button_inbound_lead')
      GA4ButtonClick({
        buttonLabel: props.eventLabel,
        buttonText:
          typeof props.children === 'string' ? props.children : undefined,
        buttonDestination: props.href,
      })
  }
  const {
    asPath, push, replace
  } = useRouter()
  const { utmData } = useGlobalState()

  if (props.href) {
    const currentPathname: string = asPath.split('?').shift() || ''
    const buttonLinkPathname: string = getPathnameFromURL(props.href || '')
    const arePathnamesTheSame = currentPathname === buttonLinkPathname

    const handleButtonClick: MouseEventHandler = (e) => {
      if (!arePathnamesTheSame || props.openInNewTab) {
        customButtonOnClick()
        return
      }

      e.preventDefault()

      const hash = props.href?.includes('#') && props.href.split('#').pop()

      if (!hash) {
        push(props.href || '')
      } else {
        push(
          props.href?.split('#').shift() || '',
          props.href?.split('#').shift() || '',
          {
            scroll: false,
          }
        ).then(() => {
          replace(props.href || '')
        })
      }
      customButtonOnClick()
    }

    const customHref = useMemo(() => {
      if (!props.href) return ''

      const isHrefToForm = props.href?.slice(props.href.length - 5) === '#form'
      const hasUtmData = utmData && Object.keys(utmData).length > 0
      const shouldModifyHref = isHrefToForm && hasUtmData

      if (!shouldModifyHref) return props.href || ''

      const hasQuery = props.href.includes('?')
      const hrefWithNoHashForm = props.href.slice(0, -5)
      const utmDataToString =
        utmData &&
        Object.keys(utmData)
          .map((key) => `${key}=${utmData[key]}`)
          .join('&')

      if (hasQuery) {
        return hrefWithNoHashForm + '&' + utmDataToString + '#form'
      } else {
        return hrefWithNoHashForm + '?' + utmDataToString + '#form'
      }
    }, [props.href])

    const renderButtonLink = () => (
      <ButtonLink
        onClick={handleButtonClick}
        href={arePathnamesTheSame ? customHref : undefined}
        ref={ref}
        target={props.openInNewTab ? '_blank' : undefined}
        size={props.size}
        icon={props.icon}
        disabled={props.disabled}
        style={props.style}
        className={props.className}
        buttonTheme={props.buttonTheme && getSectionTheme(props.buttonTheme)}
        iconAlignment={props.iconAlignment}
      >
        <span>{props.children}</span>
        {props.icon && <Icon />}
      </ButtonLink>
    )

    if (arePathnamesTheSame) {
      return renderButtonLink()
    }

    return (
      <Link
        href={customHref}
        passHref
        legacyBehavior
      >
        {renderButtonLink()}
      </Link>
    )
  }
  // TODO: add click to push props.eventLabel
  return (
    <ButtonComponent
      ref={ref}
      onClick={customButtonOnClick}
      variant={props.variant}
      type={props.type ? props.type : 'button'}
      size={props.size}
      icon={props.icon}
      disabled={props.disabled}
      style={props.style}
      className={props.className}
      buttonTheme={props.buttonTheme && getSectionTheme(props.buttonTheme)}
      iconAlignment={props.iconAlignment}
    >
      <span>{props.children}</span>
      {props.icon && <Icon />}
    </ButtonComponent>
  )
}

export default forwardRef(Button)

import React from 'react'
import { animated } from 'react-spring'
import Image from 'next/image'

import { useWindowSize } from '@/hooks'
import { AssetRenderer, Container } from '@/components'
import { BannerProps } from '@/interfaces/banner'

import { HeroImageWrapper } from '../../../../components/HeroImage'
import HeaderText from '../HeaderText'
import { useHeroImageSpring, useDataTransition } from '../../hooks'

import {
  BackgroundImageWrapper,
  ContentWrapper,
  LeftContainer,
  ProductName,
  SubCopy,
  ButtonsWrapper,
} from '../../AboveTheFold.styles'
import {
  CustomAboveTheFoldWrapper,
  HomepageHeroImageImg,
  HomepageHeroImageImgWithAnimation,
  HomepagePrimaryButton,
  HomepageSecondaryButton,
} from './Homepage.styles'

const MOBILE_BREAKPOINT = 600

const HomepageAboveTheFold: React.FC<BannerProps & {
  noPadding?: boolean
  noLocalTheme?: boolean
}> = ({
  productName,
  header,
  subCopy,
  primaryButtonLink,
  primaryButtonOpenInNewTab,
  primaryButtonText,
  primaryButtonEventLabel,
  backgroundImage,
  heroImages,
  secondaryButtonText,
  secondaryButtonLink,
  secondaryButtonEventLabel,
  secondaryButtonOpenInNewTab,
  alignment,
  noPadding,
}) => {
  const allHeroImages = heroImages?.filter(Boolean)
  const containsHeroImages = allHeroImages?.length
  const isUsingSingleHeroImage = allHeroImages?.length === 1
  const isUsingMultipleHeroImages = allHeroImages?.length > 1
  const hasButtons = (primaryButtonLink && primaryButtonText) || (secondaryButtonLink && secondaryButtonText)
  const isCenterAlign = alignment === 'Center'

  const {
    currentHeroImage,
    currentSubCopy,
    isAnimationStopped,
    isFirstItem,
  } = useDataTransition({ allHeroImages })
  const heroImageSpring = useHeroImageSpring(isAnimationStopped, isFirstItem)
  const { width } = useWindowSize()

  const isShowMobileBgImage = !containsHeroImages ||
  (isUsingSingleHeroImage && !allHeroImages[0]?.image?.url) ||
  (isUsingMultipleHeroImages && !currentHeroImage?.url)

  const isShowHeroImage =
    allHeroImages?.length > 0 &&
    ((isUsingMultipleHeroImages && currentHeroImage?.url) ||
      (isUsingSingleHeroImage && allHeroImages[0]?.image?.url)) 

  return (
    <CustomAboveTheFoldWrapper noPadding={noPadding}>
      <Container style={{ position: 'relative' }}>
        {backgroundImage?.url && (
          <BackgroundImageWrapper>
            <Image
              src={backgroundImage.url}
              alt={
                backgroundImage.description ||
                'Homepage above the fold background'
              }
              width={743}
              height={778}
              quality={100}
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
                objectPosition: 'top left',
              }}
            />
          </BackgroundImageWrapper>
        )}

        <ContentWrapper
          isCenterAlign={isCenterAlign}
          isFullWidth={!containsHeroImages && !backgroundImage?.url}
        >
          <LeftContainer>
            {productName && (
              <ProductName isCenterAlign={isCenterAlign}>
                {productName}
              </ProductName>
            )}
            <HeaderText
              header={header}
              alignment={alignment}
              heroImages={heroImages}
              currentSubCopy={currentSubCopy}
              isFirstItem={isFirstItem}
              noMobileBr
            />
            {subCopy && (
              <SubCopy isCenterAlign={isCenterAlign}>{subCopy}</SubCopy>
            )}
            {hasButtons && (
              <ButtonsWrapper
                buttonLength={
                  (primaryButtonLink && primaryButtonText ? 1 : 0) +
                  (secondaryButtonLink && secondaryButtonText ? 1 : 0)
                }
              >
                {primaryButtonLink && primaryButtonText && (
                  <HomepagePrimaryButton
                    href={primaryButtonLink}
                    openInNewTab={primaryButtonOpenInNewTab}
                    eventLabel={primaryButtonEventLabel}
                  >
                    {primaryButtonText}
                  </HomepagePrimaryButton>
                )}
                {secondaryButtonLink && secondaryButtonText && (
                  <HomepageSecondaryButton
                    href={secondaryButtonLink}
                    openInNewTab={secondaryButtonOpenInNewTab}
                    eventLabel={secondaryButtonEventLabel}
                  >
                    {secondaryButtonText}
                  </HomepageSecondaryButton>
                )}
              </ButtonsWrapper>
            )}
          </LeftContainer>

          {/* Banner's hero images */}
          {isShowHeroImage && (
            <>
              {isUsingSingleHeroImage && allHeroImages[0]?.image?.url && (
                <AssetRenderer
                  className="hero-image"
                  asset={allHeroImages[0]?.image}
                  ImageComponent={HomepageHeroImageImg}
                />
              )}
              {isUsingMultipleHeroImages && currentHeroImage?.url && (
                <AssetRenderer
                  className="hero-image-with-animation"
                  asset={currentHeroImage}
                  ImageComponent={HomepageHeroImageImgWithAnimation}
                  imageProps={{
                    as: animated.img,
                    style: heroImageSpring,
                  }}
                />
              )}
            </>
          )}

          {/* Banner's background image */}
          {width < MOBILE_BREAKPOINT &&
            isShowMobileBgImage &&
            backgroundImage?.url && (
            <HeroImageWrapper mobileOnly>
              {backgroundImage?.url && (
                <Image
                  src={backgroundImage.url}
                  alt={
                    backgroundImage.description ||
                      'Homepage above the fold background mobile'
                  }
                  width={315}
                  height={330}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    objectPosition: 'top center',
                  }}
                />
              )}
            </HeroImageWrapper>
          )}
        </ContentWrapper>
      </Container>
    </CustomAboveTheFoldWrapper>
  )
}

export default HomepageAboveTheFold

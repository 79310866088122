import React from 'react'
import { useHighlightString } from '@/hooks'
import { BannerProps } from '@/interfaces/banner'
import { useHighlightedSubCopySpring } from '../../hooks'
import {
  Header,
  HighlightedHeader,
  HighlightedWord,
  AnimatedWord,
  MobileBr,
} from './HeaderText.styles'

interface IHeaderTextProps {
  header: BannerProps['header']
  alignment: BannerProps['alignment']
  heroImages: BannerProps['heroImages']
  currentSubCopy?: string
  noMobileBr?: boolean
  isFirstItem: boolean
}

const HeaderTextComponent: React.FC<IHeaderTextProps> = ({
  header,
  alignment,
  heroImages,
  currentSubCopy,
  isFirstItem,
  noMobileBr,
}) => {
  if (!header) return null

  const isCenterAlign = alignment === 'Center'
  const allHeroImages = heroImages?.filter(Boolean)
  const isUsingMultipleHeroImages = allHeroImages?.length > 1
  const highlightedSubCopySpring = useHighlightedSubCopySpring(isFirstItem)
  const arrayOfHeaderText = header?.split('[[]]')

  const rotatingText = isUsingMultipleHeroImages && (
    <HighlightedHeader>
      {currentSubCopy?.split(' ').map((word, index) => (
        <HighlightedWord key={index}>
          <AnimatedWord style={highlightedSubCopySpring}>
            {word}
          </AnimatedWord>
          &nbsp;
        </HighlightedWord>
      ))}
      {!noMobileBr && <MobileBr />}
    </HighlightedHeader>
  )

  if (arrayOfHeaderText?.length < 2) {
    return (
      <Header isCenterAlign={isCenterAlign}>
        {useHighlightString(header + ' ', 'span')}
        {!noMobileBr && header && <MobileBr />}
        {rotatingText}
      </Header>
    )
  }

  return (
    <Header isCenterAlign={isCenterAlign}>
      {arrayOfHeaderText
        ?.map(text => text?.replace(/^\s+/g, ''))
        ?.map((text, index) => index !== arrayOfHeaderText?.length - 1 ? (
          <React.Fragment key={index}>
            {useHighlightString(text, 'span')}
            {!noMobileBr && text && <MobileBr />}
            {rotatingText}
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            {useHighlightString(text, 'span')}
          </React.Fragment>
        ))}
    </Header>
  )
}

export default HeaderTextComponent

import dayjs from 'dayjs'
import { excludeEmptyItem, tagGenerator } from '@/utils'
import { ArticleProps } from '@/interfaces/article'
import {
  Container,
  SocialSharing,
  RichText,
  DynamicLink,
} from '@/components'
import {
  Wrapper,
  ItemList,
  ItemListWithSpacing,
  TopicName,
  Title,
  CreatedDate,
  TagTitle,
  Tag,
  HeroImage,
  ArticleContent,
  TagListAndSocialSharingWrapper,
  TopSocialSharingWrapper,
  BelowSocialSharingWrapper
} from './Article.styles'
import YouMightAlsoLike from './YouMightAlsoLike'
import { useCurrentURL } from '@/hooks'

const Article: React.FC<ArticleProps> = (props) => {
  const {
    title,
    topics,
    tags,
    date,
    heroImage,
    content,
    relatedArticles,
  } = props
  const topicList = excludeEmptyItem(topics)
  const tagList = excludeEmptyItem(tags)
  const currentURL = useCurrentURL()
  const hasTagList = !!tagList?.length

  return (
    <>
      <Wrapper>
        <Container>
          <ItemList>
            {topicList?.map((item: any) => {
              if (!item.title) return null
              return (
                <DynamicLink
                  href={'/insights' + (item.pageSlug || '')}
                  key={item.sys?.id}
                >
                  <TopicName>{item.title}</TopicName>
                </DynamicLink>
              )
            })}
          </ItemList>
          <Title>{title}</Title>
          <TagListAndSocialSharingWrapper hasTagList={hasTagList}>
            <div>
              {date && <CreatedDate hasTagList={hasTagList}>{dayjs(date).format('MMM DD, YYYY')}</CreatedDate>}
              {
                tagList && tagList.length > 0 &&
                <ItemListWithSpacing>
                  {tagList.map((item: any) => {
                    if (!item.title) return null
                    return (
                      <DynamicLink
                        href={'/insights' + (item.pageSlug || '')}
                        key={item.sys?.id}
                      >
                        <Tag>{tagGenerator(item.title)}</Tag>
                      </DynamicLink>
                    )
                  })}
                </ItemListWithSpacing>
              }
            </div>
            <TopSocialSharingWrapper>
              <SocialSharing
                url={currentURL}
                text={title}
              />
            </TopSocialSharingWrapper>
          </TagListAndSocialSharingWrapper>
          <HeroImage src={heroImage?.url} alt={heroImage?.description} />
          {content && (
            <ArticleContent className='custom__richtext'>
              <RichText richText={content} />
            </ArticleContent>
          )}
          {!!tagList?.length && (
            <>
              <TagTitle>Tags</TagTitle>
              <ItemList>
                {tagList?.map((item: any) => {
                  if (!item.title) return null
                  return (
                    <DynamicLink
                      href={'/insights' + (item.pageSlug || '')}
                      key={item.sys?.id}
                    >
                      <Tag>{tagGenerator(item.title)}</Tag>
                    </DynamicLink>
                  )
                })}
              </ItemList>
            </>
          )}
          <BelowSocialSharingWrapper>
            <SocialSharing
              title='Share this article'
              url={currentURL}
              text={title}
            />
          </BelowSocialSharingWrapper>
        </Container>
      </Wrapper>
      {relatedArticles?.length ? <YouMightAlsoLike relatedArticles={relatedArticles} /> : null}
    </>
  )
}

export default Article

import { documentToReactComponentsWithBr, getSectionTheme } from '@/utils'
import { LeadsWrapperProps } from '@/interfaces/leadsWrapper'
import LocalThemeProvider from '@/components/LocalThemeProvider'
import LeadForm from '@/components/Form/templates/LeadForm'
import {
  BannerBackgroundContainer,
  BannerContainer,
  BannerLeftWrapper,
  Title,
  SubCopyContainer,
  BannerButton,
  BannerRightWrapper,
} from './LeadGenBanner.styles'

const LeadGenBanner: React.FC<LeadsWrapperProps> = (props) => {
  const {
    title,
    leadSubCopy,
    leadsForm,
    backgroundImage,
    buttonText,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
    alignment,
    theme,
  } = props

  return (
    // If no backgroundImage, default color will be paleGray
    <LocalThemeProvider theme={{ sectionTheme: getSectionTheme(theme) }}>
      <BannerBackgroundContainer backgroundImage={backgroundImage?.url}>
        <BannerContainer>
          <BannerLeftWrapper hasNoForm={!leadsForm} alignment={alignment}>
            <Title $sectionTheme={theme}>{title}</Title>
            {
              leadSubCopy &&
              <SubCopyContainer>
                {documentToReactComponentsWithBr(leadSubCopy.json)}
              </SubCopyContainer>
            }
            {
              !leadsForm && buttonLink && buttonText && (
                <BannerButton
                  href={buttonLink}
                  eventLabel={buttonEventLabel}
                  openInNewTab={openInNewTab}
                >
                  {buttonText}
                </BannerButton>
              )
            }
          </BannerLeftWrapper>
          {
            leadsForm &&
            <BannerRightWrapper>
              <LeadForm {...leadsForm} />
            </BannerRightWrapper>
          }
        </BannerContainer>
      </BannerBackgroundContainer>
    </LocalThemeProvider>
  )
}

export default LeadGenBanner

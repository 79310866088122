import { useContext } from 'react'
import { Button } from '@/components'
import SubscribeFormComponent from '../SubscribeForm'
import LinkedInLogo from '../../../../public/images/icons/linkedin.svg'
import {
  ParcelPerformSection,
  Logo,
  Description,
  SubscribeFormLabel,
} from './ParcelPerformSection.styles'
import { FooterContext } from '../Footer'

const ParcelPerformSectionComponent: React.FC = () => {
  const footerContext = useContext(FooterContext)

  return (
    <ParcelPerformSection>
      <Logo src='/images/parcel-perform-logo.svg' alt='Parcel Perform logo' />
      {footerContext?.title && <Description>{footerContext.title}</Description>}
      <Button
        variant='icon'
        href='https://www.linkedin.com/company/parcel-perform/'
        openInNewTab
        style={{ marginBottom: 26 }}
      >
        <LinkedInLogo />
      </Button>
      {footerContext?.newsletterTitle && <SubscribeFormLabel>{footerContext?.newsletterTitle}</SubscribeFormLabel>}
      <SubscribeFormComponent />
    </ParcelPerformSection>
  )
}

export default ParcelPerformSectionComponent

import getConfig from 'next/config'
import { excludeEmptyItem, truncateTextWithEllipsis } from '@/utils'
import { ArticleProps, IPmArticle } from '@/interfaces/article'
import ArrowRight from '../../public/images/icons/arrow-right.svg'
import { startAndEndDateFormat } from '@/utils/dateTime.utils'
import {
  ThumbnailLinkContainer,
  HeroImage,
  ThumbnailContentContainer,
  Title,
  SubCopy,
  CreatedDate,
  ThumbnailItemListWrapper,
  ThumbnailItemList,
  ThumbnailTopicName,
  Description,
  ThumbnailOverlayLink,
} from './Thumbnail.styles'
import { EventType, IEvent } from '@/interfaces/eventsSection'
import Button from '../Button'

const { publicRuntimeConfig } = getConfig()

type ThumbnailProps = Partial<ArticleProps & IEvent & IPmArticle>

const resourceSectionType = ['Success Stories', 'Ebooks', 'Reports']

const Thumbnail: React.FC<ThumbnailProps> = (props) => {
  const {
    title,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    description,
    articleSlug,
    articleLang,
    topics,
    thumbnailCopy,
    date: articleDate,
    heroImage,
    location,
    eventSlug,
    buttonText,
    buttonLink,
    startDate,
    endDate,
    type,
    greyLabel,
    sys,
    slug,
    articleType,
    openInNewTab,
  } = props
  const eventLabel = type && sys && [{ title: type, sys }]
  const topicList = eventLabel || excludeEmptyItem(topics)
  const articleLangSlug = articleLang ? `/${articleLang}` : ''
  const href = buttonLink || eventSlug || `/insights${articleLangSlug}${articleSlug || ''}`
  const descriptionThumbnail = location || thumbnailCopy
  const date = articleDate || startDate
  const thumbnailTitle = !title ? '' : eventSlug ? title : truncateTextWithEllipsis(title, 102)
  const isShowEventButton = buttonLink || eventSlug
  const isResourceType = type && resourceSectionType.includes(type)

  const hasEventButton = !!(isShowEventButton && buttonText)
  const isPmArticle = !!(type === EventType.PARCEL_MONITOR_REPORT && slug)
  const showButton = hasEventButton || isPmArticle

  const customHref = !isPmArticle ? href : `https://${publicRuntimeConfig.pmcDomain}/${articleType}/${slug}`
  const customOpenInNewTab = !isPmArticle ? openInNewTab : true

  const renderButton = () => {
    if (!showButton) return null

    return <Button
      variant='link'
      href={customHref}
      icon={ArrowRight}
      openInNewTab={customOpenInNewTab}
      style={{
        position: 'relative',
        zIndex: 2,
      }}
    >
      {hasEventButton ? buttonText : 'Download'}
    </Button>
  }

  return (
    <ThumbnailLinkContainer>
      <ThumbnailOverlayLink
        href={customHref}
        style={{ display: 'inline-block' }}
        isOpenNewTab={customOpenInNewTab}
      />
      {heroImage && <HeroImage alt={heroImage.description} src={heroImage.url} />}
      <ThumbnailContentContainer>
        {topicList && !greyLabel &&
          <ThumbnailItemListWrapper>
            <ThumbnailItemList>
              {topicList.map((item: any) => {
                if (!item.title) return null
                return (
                  <ThumbnailTopicName
                    key={item.sys.id}
                    isPmArticle={isPmArticle}
                  >
                    {item.title}
                  </ThumbnailTopicName>
                )
              })}
            </ThumbnailItemList>
          </ThumbnailItemListWrapper>
        }
        {greyLabel &&
          <ThumbnailItemListWrapper>
            <ThumbnailItemList>
              {greyLabel.slice(0, 1).map((item: any, index: number) => {
                return (
                  <ThumbnailTopicName key={index} isPmArticle={isPmArticle}>
                    {item}
                  </ThumbnailTopicName>
                )
              })}
            </ThumbnailItemList>
          </ThumbnailItemListWrapper>
        }
        {thumbnailTitle && <Title>{thumbnailTitle}</Title>}
        {!isResourceType && descriptionThumbnail && <SubCopy>{truncateTextWithEllipsis(descriptionThumbnail, 120)}</SubCopy>}
        {description && <Description>{truncateTextWithEllipsis(description, 120)}</Description>}
        {date && <CreatedDate>{startAndEndDateFormat(date, endDate)}</CreatedDate>}
        {renderButton()}
      </ThumbnailContentContainer>
    </ThumbnailLinkContainer>
  )
}

export default Thumbnail

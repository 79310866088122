import Link from 'next/link'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import { Container } from '@/components'
import {
  Header,
  TopSection,
  Logo,
} from './Header.styles'

import { useWindowSize } from '@/hooks'

export interface HeaderComponentProps {
  headerItems: any[]
  currentDomain: string
  hideHeaderFooter?: boolean
}

const MOBILE_BREAKPOINT = 599

const HeaderComponent: React.FC<HeaderComponentProps> = ({
  headerItems,
  currentDomain,
  hideHeaderFooter,
}) => {
  const { width: viewportWidth } = useWindowSize()

  const headerProps = {
    headerItems,
    currentDomain
  }

  return (
    <Header>
      <Container>
        <TopSection>
          <Link
            href='/'
            passHref
            prefetch={false}
            legacyBehavior
          >
            <Logo>
              <img
                src='/images/parcel-perform-logo.svg'
                alt='Parcel Perform logo'
              />
            </Logo>
          </Link>
          { !hideHeaderFooter && ( 
            viewportWidth > MOBILE_BREAKPOINT 
              ? <HeaderDesktop {...headerProps}/>
              : <HeaderMobile {...headerProps}/>
          )}
        </TopSection>
      </Container>
    </Header>
  )
}

export default HeaderComponent

// TODO-Typo: NOT EDIT YET, no font system
import styled from 'styled-components'

export const ParcelPerformSection = styled.div`
  margin-bottom: 39px;

  ${({ theme }) => theme.mediaBreakpointUp.md}  {
    flex: 0 0 304px;
    max-width: 304px;
    margin-bottom: 0;
    margin-right: 56px;
  }
`

export const Logo = styled.img`
  width: 281px;
  position: relative;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 248px;
    height: 40.91px;
    object-fit: cover;
  }
`

export const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[18]};
  line-height: ${({ theme }) => theme.lineHeight[25]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 24px;
`

export const SubscribeFormLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[14]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: ${({ theme }) => theme.lineHeight[19]};
  margin-bottom: 16px;
  text-transform: uppercase;
`

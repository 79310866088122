import { BannerProps } from '@/interfaces/banner'
import { ProductSuiteWrapper, HeroImage } from './ProductSuite.styles'
import { AssetRenderer } from '@/components'

const ProductSuite: React.FC<BannerProps> = ({ heroImages }) => {
  const firstHeroImage = heroImages?.[0]

  return (
    <ProductSuiteWrapper>
      {firstHeroImage && (
        <AssetRenderer
          asset={firstHeroImage?.image}
          ImageComponent={HeroImage}
        />
      )}
    </ProductSuiteWrapper>
  )
}

export default ProductSuite

import styled from 'styled-components'
import Button from '@/components/Button'

export const TopRightSection = styled.div`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    align-items: center;
  }
`

export const LoginButtonAndCountrySelectorSeparator = styled.div`
  background: white;
  width: 1px;
  height: 24px;
  margin-left: 13px;
  margin-right: 13px;
`

// NEW-BUTTON: Filled - Product Experience
export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.white};
  padding: 15px;
  margin-left: 16px;

  :not([disabled]):hover {
    background: ${({ theme }) => theme.colors.white05};
    border-color: ${({ theme }) => theme.colors.white};
  }
`

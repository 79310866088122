import styled, { css } from 'styled-components'
import { Alignment, TextStyles } from '@/constants'
import Button from '@/components/Button'
import Typography from '@/components/Typography'
import { styledText } from '@/components/Typography/Typography.styles'

export const CustomerCardWrapper = styled.div<{ alignment?: Alignment }>`
  display: flex;
  background: #f7f8f9;
  height: 430px;
  width: 280px !important;
  box-shadow: 0px 10.7161px 21.4323px -5.14374px rgba(0, 0, 0, 0.25);
  border-radius: 6.72541px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 486px;
    flex-direction: ${({ alignment }) => alignment === Alignment.Right && 'row-reverse'};
    width: auto !important;
    box-shadow: none;
    border-radius: 0;
  }
`

export const ImageWrapper = styled.span`
  display: none;
  position: relative;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 486px;
    display: block;
    overflow: hidden;

    > span, .dotlottie-container {
      top: 107px;
    }
  }
`

export const Circle = styled.div<{ alignment?: Alignment }>`
  display: none;
  position: absolute;
  width: 700px;
  height: 700px;
  border-radius: 50%;
  box-sizing: content-box;
  top: 50%;
  transform: translateY(-50%);
  ${({ alignment }) => {
    return alignment === Alignment.Right
      ? css`
          left: -110px;
        `
      : css`
          right: -110px;
        `
  }}

  border: 110px solid #f7f8f9;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
  }
`

// [Card Content]
export const CustomerCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  padding: 25px 26.5px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 48px;
  }
`

export const CustomerLogo = styled.img`
  width: 100%;
  margin-bottom: 22px;
  align-self: flex-start;
  object-fit: contain;
  object-position: center top;
  height: 41px;
  max-width: 227px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none !important;
  }
`

// [Card Content - Testimonial]

export const Testimonial = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  line-height: ${({ theme }) => theme.lineHeight[24]};
  justify-content: space-between;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex-direction: column;
    justify-content: unset;
    font-size: ${({ theme }) => theme.fontSizes[16]};
  }
`

export const Quote = styled.div`
  ${styledText[TextStyles['Small Paragraph Bold']]}  
  color: ${({ theme }) => theme.colors.darkBlue};
  max-height: 329px;
  margin-bottom: auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 3']]}
    flex: 1;
    max-height: initial;
  }
`

export const QuotationMark = styled.span`
  color: ${({ theme }) => theme.pageTheme?.main ?? theme.colors.orange};
`

// TODO-Design: Spacing not match Figma, update later
export const Customer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 0;
    margin-bottom: 34px;
  }
`

export const CustomerName = styled.div`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.darkBlue};
  ${styledText[TextStyles['Small Paragraph Bold']]}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Large Paragraph Bold']]}
  }
`

export const CustomerTitle = styled(Typography).attrs({
  variant: TextStyles['Small Paragraph Text'],
})`
  color: ${({ theme }) => theme.colors.grayBlue};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Large Paragraph Text']]}
  }
`

export const CustomerImage = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin-right: 13px;
  position: relative;
  overflow: hidden;
  flex: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 52px;
    height: 52px;
    margin-right: 16px;
  }
`

export const ButtonLink = styled(Button)`
  margin-top: 18px;
  flex: none; // to keep button's height

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 32px;
  }
`

// [/Card Content - Testimonial]
// [/Card Content]

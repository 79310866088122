import styled from 'styled-components'

export const SECTION_WRAPPER_PADDING_DESKTOP = 80
export const SECTION_WRAPPER_PADDING_MOBILE = 32

const SectionWrapper = styled.div`
  padding-top: ${SECTION_WRAPPER_PADDING_MOBILE}px;
  padding-bottom: ${SECTION_WRAPPER_PADDING_MOBILE}px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: ${SECTION_WRAPPER_PADDING_DESKTOP}px;
    padding-bottom: ${SECTION_WRAPPER_PADDING_DESKTOP}px;
  }
`

export default SectionWrapper
